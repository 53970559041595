const paths = {
  ADMANAGER: {
    BASE: 'admanager',
    CREATIVES: 'creatives',
    LINEITEMS: 'lineitems',
    NETWORKS: 'networks',
    ORDERS: 'orders',
  },
  DASHBOARD: {
    BASE: 'dashboard',
    HOME: '',
  },
  DOCS: {
    BASE: 'docs',
    BACKLOG: 'backlog',
    DATA_REFRESH: 'data-refresh',
    DEPRECATION: 'deprecation',
    DOCS: 'docs',
    RELEASES: 'releases',
    PRIVACY_POLICY: 'privacy-policy',
    WHITELIST: 'whitelist',
  },
  REPORTS: {
    // Base paths.
    BASE: 'reports',
    DELIVERY: 'delivery',
    PROGRAMMATIC: 'programmatic',

    // Report paths.
    DISCREPANCY: 'discrepancy',
    PACING: 'pacing',
    ADX_HEALTH: 'adx-health',
    NETWORK_HEALTH: 'network-health',
    YIELD_PARTNER: 'adx-and-ob',
  },
  INTERNAL: {
    BASE: 'internal',
    EXPLORE: 'explore',
    ORGANIZATION: 'organization',
    PERSON: 'person',
    PROJECT: 'project',
    TIME: 'time',
  },
  CUSTOM_REPORTS: {
    BASE: 'custom-reports',
    CAMPAIGN_TRACKING: 'campaign-tracking',
    CAMPAIGN_TRACKING_1: 'campaign-tracking-1',
    CAMPAIGN_TRACKING_2: 'campaign-tracking-2',
    MONTHLY_PROGRESS: 'monthly-progress',
    PROGRESS_REPORT: 'progress-report',
    REVENUE: 'revenue',
    REVENUE_AT_RISK: 'revenue-at-risk',
    SCHNEPS_MID_MONTH: 'schneps-mid-month',
  },
}

export default paths
