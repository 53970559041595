const router = {
  props(route) {
    const { params, query } = route

    if (query.adjuster && query.adjuster === 'true') {
      params.adjuster = true
    }

    if (typeof params.reportName === 'undefined') {
      params.reportName = 'dashboard'
    }

    if (typeof params.subId === 'undefined') {
      params.subId = undefined
    }

    return {
      ...params,
      query,
    }
  },
}

export default router
