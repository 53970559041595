import Vue from 'vue'
import Router from 'vue-router'

import routes from './routes'
import router from './router'

Vue.use(Router)

const { BASE_URL } = process.env

export default new Router({
  mode: 'history',
  base: BASE_URL,
  routes: [
    {
      component: routes.MAIN_CONTENT.component,
      meta: { title: 'Ad Manager' },
      name: 'admanager',
      path: '/admanager/:reportName?/:reportId?/:subId?',
      props: router.props,
    },
    {
      component: routes.MAIN_CONTENT.component,
      meta: { title: 'Reports' },
      name: 'reports',
      path: '/reports/:reportName?/:reportId?/:subId?',
      props: router.props,
    },
    {
      // Redirecting, so no component is needed.
      // component: routes.MAIN_CONTENT.component,
      meta: { title: 'Delivery' },
      name: 'delivery',
      path: '/delivery/:reportName?/:reportId?/:subId?',
      props: router.props,
      redirect: (to) => {
        const { reportId, reportName, subId } = to.params
        const reportPaths = ['/reports']

        if (reportName) {
          reportPaths.push(reportName)

          if (reportId) {
            reportPaths.push(reportId)

            if (subId) {
              reportPaths.push(subId)
            }
          }
        }

        return {
          path: reportPaths.join('/'),
        }
      },
    },
    {
      // Redirecting, so no component is needed.
      // component: routes.MAIN_CONTENT.component,
      meta: { title: 'Programmatic' },
      name: 'programmatic',
      path: '/programmatic/:reportName?/:reportPath?/:reportId?/:subId?',
      props: router.props,
      redirect: (to) => {
        const { reportId, reportName, subId } = to.params
        const reportPaths = ['/reports']

        if (reportName) {
          const reportNameMap = {
            adx: 'adx-health',
            network: 'network-health',
            partner: 'adx-and-ob',
          }

          const newReportName = reportNameMap[reportName] || reportName

          reportPaths.push(newReportName)

          if (reportId) {
            reportPaths.push(reportId)

            if (subId) {
              reportPaths.push(subId)
            }
          }
        }

        return {
          path: reportPaths.join('/'),
        }
      },
    },
    {
      component: routes.MAIN_CONTENT.component,
      meta: { title: 'Docs' },
      name: 'docs',
      path: '/docs/:reportName?/:reportId?',
      props: router.props,
    },
    {
      component: routes.MAIN_CONTENT.component,
      meta: { title: 'Internal' },
      name: 'internal',
      path: '/internal/:reportName?/:reportId?',
      props: router.props,
    },
    {
      component: routes.MAIN_CONTENT.component,
      meta: { title: 'Custom Reports' },
      name: 'custom-reports',
      path: '/custom-reports/:reportName?/:reportId?',
      props: router.props,
    },
    {
      component: routes.MAIN_CONTENT.component,
      meta: { title: 'Dev' },
      name: 'dev',
      path: '/dev/:reportName?',
      props: router.props,
    },
    {
      component: routes.MAIN_CONTENT.component,
      meta: { title: 'Sign In' },
      name: 'sign-in',
      path: '/signin',
      props: router.props,
    },
    {
      component: routes.MAIN_CONTENT.component,
      meta: { title: 'Sign Out' },
      name: 'sign-out',
      path: '/signout',
      props: router.props,
    },
    {
      component: routes.MAIN_CONTENT.component,
      meta: { title: 'Index' },
      name: 'index',
      path: '/index.html',
      props: router.props,
    },
    {
      component: routes.MAIN_CONTENT.component,
      meta: { title: 'Dashboard' },
      name: 'dashboard',
      path: '/',
      props: router.props,
    },
  ],
})
